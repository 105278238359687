import React from "react";
import { Contract } from "@ethersproject/contracts";
import airdropAbi from "./airdropAbi.json";
import { airdropAddress } from "./environment";
import { ethers } from "ethers";
let walletAddress = "0x0A7bCd3F65F9599c317a30A126Bc23229818e437";

const provider = new ethers.providers.JsonRpcProvider(
  "https://polygon-rpc.com/"
);

export const voidAccount = new ethers.VoidSigner(walletAddress, provider);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useAirdropContract(signer) {
  return useContract(airdropAddress, airdropAbi, signer);
}
