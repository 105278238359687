import React, { useContext } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { AppContext } from "../utils";
import logo from "../images/logo.png";

export default function Header() {
  const { account, connect, disconnect } = useContext(AppContext);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          background: "transparent",
          zIndex: "100px",
        }}
        height="150px"
        width="100%"
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <img width="130px" src={logo} alt="" />

            {account ? (
              <Box
                width="130px"
                height="42px"
                bgcolor="#9E6D48"
                borderRadius="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="#fff"
                fontWeight="500"
                fontFamily="Nunito"
                fontSize="16px"
                sx={{
                  "&:hover": {
                    background: "#252525",
                  },
                  cursor: "pointer",
                }}
                onClick={() => disconnect()}
                style={{ zIndex: 1 }}
              >
                {account.slice(0, 4) + "..." + account.slice(-4)}
              </Box>
            ) : (
              <Box
                zIndex={1}
                bgcolor="#9E6D48"
                width="130px"
                height="42px"
                fontWeight="500"
                fontFamily="Nunito"
                borderRadius="8px"
                fontSize="20px"
                color="#fff"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "&:hover": {
                    background: "#9E6D48a1",
                  },
                  cursor: "pointer",
                }}
                onClick={() => connect()}
              >
                Connect
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
