import React, { useEffect, useState } from "react";
import Web3 from "web3";
import "./App.css";
import Airdrop from "./components/Airdrop";
import Header from "./components/Header";
import NetworkChange from "./networkSwitch";
const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://polygon-rpc.com/"
);
function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let chain = async () => {
      const chainid = await web3.eth.getChainId();
      if (chainid !== 137) {
        setOpen(true);
      }
    };
    chain();
  }, []);

  return (
    <>
      <NetworkChange open={open} setOpen={setOpen} />
      <Header />
      <Airdrop />
    </>
  );
}

export default App;
