import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useAirdropContract } from "../ConnectivityAss/hooks";
import Loading from "../loading";
import { AppContext } from "../utils";
// import CSVUpload from "./CSVUpload";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { formatUnits, parseUnits } from "@ethersproject/units";
import logo from "../images/logo.png";

export default function Airdrop() {
  // const [arrayOfAddresses, setarrayOfAddresses] = useState([]);
  const [loading, setloading] = useState(false);
  const { account, signer } = useContext(AppContext);
  const airdropContract = useAirdropContract(signer);
  const [walletFunctionAmount, setWalletFunctionAmount] = useState(0);

  const init = useCallback(async () => {
    if (!account) {
      return toast.error("Please, Connect your wallet first.");
    }
    let walletAmount = await airdropContract.wallets(account);
    setWalletFunctionAmount(+formatUnits(walletAmount).toString(), 18);
  }, [account, airdropContract]);

  useEffect(() => {
    init();
  }, [init]);

  const claimHandler = async () => {
    try {
      setloading(true);

      if (walletFunctionAmount > 0) {
        let tx = await airdropContract.Claim({
          value: parseUnits((0.8).toString(), 18),
        });
        tx.wait();
      }
      toast.success("Claimed, Successfully");

      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else if (error?.reason) {
        toast.error(error?.reason);
      } else {
        toast.error(error?.message);
      }
    }
  };
  // const airDropHandler = async () => {
  //   if (!account) {
  //     toast.error("Please Connect your wallet");
  //   } else {
  //     try {
  //       setloading(true);
  //       // let fn = airdropContract.estimateGas.Airdrop;
  //       // let params = [arrayOfAddresses];
  //       let tx = await airdropContract.addData(arrayOfAddresses);
  //       await tx.wait();
  //       toast.success("Transection Confirmed");
  //       setloading(false);
  //     } catch (error) {
  //       setloading(false);
  //       if (error?.data?.message) {
  //         toast.error(error?.data?.message);
  //       } else if (error?.reason) {
  //         toast.error(error?.reason);
  //       } else {
  //         toast.error(error?.message);
  //       }
  //     }
  //   }
  // };
  return (
    <Box py={5}>
      <Loading loading={loading} />
      <Container maxWidth="lg">
        <Grid container spacing={5} alignItems="center">
          {/* <Grid item={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              border="2px solid #ffffff"
              borderRadius="20px"
              pt={2}
              pb={4}
              px={2}
            >
              <Box
                fontFamily="Nunito"
                mb={2}
                fontSize="30px"
                fontWeight="700"
                color="#ffffff"
              >
                Uplaod Your Csv
              </Box>
              <CSVUpload
                arrayAddress={arrayOfAddresses}
                setarrayAddress={setarrayOfAddresses}
              />
              <Box display="flex" alignItems="center">
                <Box
                  zIndex={1}
                  sx={{
                    "&:hover": {
                      background: "#9E6D48a1",
                      cursor: "pointer",
                    },
                  }}
                  bgcolor="#9E6D48"
                  width="130px"
                  height="42px"
                  fontWeight="500"
                  fontFamily="Nunito"
                  borderRadius="8px"
                  fontSize="20px"
                  color="#ffffff"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => airDropHandler()}
                  mt={2}
                >
                  Send
                </Box>
              </Box>
            </Box>
          </Grid> */}

          <Grid item xs={12} sm={12}>
            {walletFunctionAmount > 0 ? (
              <>
                <Box textAlign="center">
                  <img src={logo} alt="" style={{ width: "300px" }} />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="h4"
                    sx={{
                      width: "40%",
                      margin: "auto",
                    }}
                  >
                    Join us
                    <span
                      style={{
                        display: "block",
                        width: "70px",
                        height: "1.5px",
                        borderRadius: "50%",
                        marginBottom: "20px",
                        background: "#9E6D48",
                      }}
                    />
                  </Typography>
                  <Stack direction="row" gap={10}>
                    <a
                      href="https://twitter.com/ToraToken?t=v7sI9my2LKZk5_4C_l8tQQ&s=08"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <TwitterIcon sx={{ fontSize: "65px", color: "#fff" }} />
                    </a>
                    <a
                      href="https://t.me/TheToraToken"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <TelegramIcon sx={{ fontSize: "65px", color: "#fff" }} />
                    </a>
                  </Stack>
                  <Box
                    sx={{
                      "&:hover": {
                        background: "#9E6D48a1",
                        cursor: "pointer",
                      },
                    }}
                    bgcolor="#9E6D48"
                    width="170px"
                    height="44px"
                    fontWeight="500"
                    fontFamily="Nunito"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={5}
                    onClick={claimHandler}
                  >
                    Claim
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box textAlign="center">
                  <img src={logo} alt="" style={{ width: "300px" }} />
                </Box>
                <Box
                  textAlign="center"
                  fontSize="24px"
                  letterSpacing="3px"
                  fontWeight="700"
                  width={{ xs: "85%", md: "70%" }}
                  m="auto"
                  mb={4}
                >
                  We're sorry that you're not whitelisted but you can buy with
                  the link below and become our valued member.
                </Box>
                <a
                  href="https://quickswap.exchange/#/swap?swapIndex=1&currency0=0xD0B3A0e0ea080a0882D6E048CAe8558F010ce81f&currency1=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      "&:hover": {
                        background: "#9E6D48a1",
                        cursor: "pointer",
                      },
                    }}
                    bgcolor="#9E6D48"
                    width="170px"
                    height="44px"
                    fontWeight="500"
                    fontFamily="Nunito"
                    borderRadius="8px"
                    fontSize="20px"
                    color="#ffffff"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m="auto"
                  >
                    BUY
                  </Box>
                </a>
              </>
            )}
            {/* {+arrayOfAddresses.length > 0
                ? arrayOfAddresses.map((item, i) => {
                    return (
                      <Box
                        key={i}
                        color="#ffffff"
                        display="flex"
                        flexDirection="column"
                        fontWeight="300"
                        fontFamily="Nunito"
                        border="1px solid gray"
                        mt={0.5}
                        borderRadius="20px"
                        px={1}
                        py={0.2}
                        textAlign="center"
                      >
                        {item}
                      </Box>
                    );
                  })
                : null} */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
